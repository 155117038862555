import './App.css';
import Dashboard from './Dashboard';
import Landing from './Landing';
import Revision from './Revision';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import HomeMenu from './HomeMenu';
import RevisionMenu from './RevisionMenu';
import NotFound from './NotFound';
import Login from './Login';
import { useState } from 'react';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const router = createBrowserRouter(createRoutesFromElements(
    <>
      <Route path='/' element={<Landing/>} />
      <Route path='/login' element={<Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
      <Route path='/dashboard' element={<Dashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />}>
        <Route path='revision' element={<RevisionMenu isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path='home' element={<HomeMenu/>} />
        <Route path='' element={<HomeMenu/>} />
      </Route>
      <Route path='/revision/:id/' element={<Revision isLoggedIn={isLoggedIn} />} />
      <Route path='*' element={<NotFound/>} />
    </>
  ));  

  return (
    <RouterProvider router={ router } />
  );
}

export default App;

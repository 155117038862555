import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Loading from "./Loading";

export default function Login({setIsLoggedIn, isLoggedIn}) {

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const previousPath = searchParams.get('previous-path');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    fetch('https://www.quaesium.serv00.net/login-status', {
      credentials: 'include'
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      setIsLoading(false)
      if (data === true) {
        setIsLoggedIn(true);
        console.log(data);
      } else {
        setIsLoggedIn(false);
        console.log(data);
      }
    })
  }, [])

  const submitForm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetch('https://www.quaesium.serv00.net/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password
      }),
      credentials: 'include'
    })
    .then(response => {
      return response.text();
    })
    .then(data => {
      setIsLoading(false);
      if (data === 'success') {
        console.log('nice')
        setIsLoggedIn(true);
      } else {
        console.log('HAHA WRONG CREDENTIALS WOMP WOMP')
        setIsLoggedIn(false);
      }
    })
    .catch(error => {
      console.error('Error during login:', error);
      alert('Login failed, please try again later.');
    });
  }

  if (isLoading === true) {
    return (
      <div className="login">
        <Loading />
      </div>
    );
  } else if (isLoggedIn === true) {
    return <Navigate to={previousPath === null ? '/dashboard' : previousPath} />
  } else {
    return (
      <div className="login">
        <form onSubmit={submitForm}>
          <h1>Login:</h1>
          <input type="text" value={username} onChange={(e) => {setUsername(e.target.value)}}/>
          <input type="password" value={password} onChange={(e) => {setPassword(e.target.value)}}/>
          <input type="submit" value={'Login'} />
        </form>
        <Link to={'/dashboard'}>Dashboard</Link>
        <Link to={'/'}>Home</Link>
      </div>
    );
  }
}
import { useEffect, useState } from "react";
import { useParams, Link, useNavigate, Navigate } from "react-router-dom"
import back from './back.png';
import Question from "./Question";
import QuestionResult from "./QuestionResult";
import Loading from "./Loading";
import RevisionResult from "./RevisionResult";
import RevisionInfo from "./RevisionInfo";

export default function Revision({isLoggedIn, setIsLoggedIn}) {

    const { id } = useParams();

    const navigate = useNavigate();

    const [title, setTitle] = useState('');
    const [questions, setQuestions] = useState([]);

    const [score, setScore] = useState(0);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [result, setResult] = useState('unanswered');
    const [perfectScore, setPerfectScore] = useState();

    const [hasStarted, setHasStarted] = useState(true);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasFinished, setHasFinished] = useState(false);

    useEffect(() => {
        if (isLoggedIn === false) {
            navigate('/login?previous-path=/revision/' + id)
        }
    }, [isLoggedIn])

    useEffect(() => {
        fetch(`https://www.quaesium.serv00.net/api/revision-set/${id}`)
        .then((res) => {
            if (res.status === 401) {
                setIsLoggedIn(false);
            } else {
                return res.text();
            }
        })
        .then((str) => {
            const revisionSetObj = JSON.parse(str);
            const unRandomisedQuestions = JSON.parse(revisionSetObj.questions);

            let randomisedQuestions = [];

            for (let i = 0; i < unRandomisedQuestions.length; i++) {
                if (true) {
                    const randomisedAnswers = [...unRandomisedQuestions[i].answers]
                    console.log(unRandomisedQuestions[i].answers)
                    randomisedAnswers.sort(() => {return 0.4 - Math.random()})
                    console.log(randomisedAnswers)
                    const randomisedQuestion = {
                        title: unRandomisedQuestions[i].title,
                        answers: randomisedAnswers,
                        correct_answer: unRandomisedQuestions[i].correct_answer
                    }
                    randomisedQuestions.push(randomisedQuestion)
                } else {
                    randomisedQuestions.push(unRandomisedQuestions[i])
                }
            }

            randomisedQuestions.sort((a, b) => {return 0.8 - Math.random()})
            console.log(randomisedQuestions)

            setTitle(revisionSetObj.title)
            setQuestions(randomisedQuestions)
            setPerfectScore(randomisedQuestions.length)
            setHasLoaded(true)
        });
    }, [id])

    function handleAnswer(isCorrect) {
        if (result === 'unanswered') {
            if (isCorrect === true) {
                setResult('correct')
                setScore(score + 1)
                setTimeout(() => {
                    if (currentQuestionIndex !== questions.length - 1) {
                        setCurrentQuestionIndex(currentQuestionIndex + 1)
                        setResult('unanswered')
                    } else {
                        setHasFinished(true);
                        setResult('unanswered')
                    }
                }, 1000)
            } else {
                setResult('incorrect')
                setTimeout(() => {
                    if (currentQuestionIndex !== questions.length - 1) {
                        setCurrentQuestionIndex(currentQuestionIndex + 1)
                        setResult('unanswered')
                    } else {
                        setHasFinished(true);
                        setResult('unanswered')
                    }
                }, 1000)
            }
        }
    }

    if (isLoggedIn === false) {
        return <Navigate to={'/login?previous-path=/revision/' + id}/>
    } else if (hasStarted === false) {
        return (
            <div className="revision">
                <RevisionInfo id={id}/>
            </div>
        );
    } else if (hasLoaded === false) {
        return (
            <div className="revision">
                <Loading />
            </div>
        );
    } else {
        return (
            <div className="revision">
                <nav>
                    <Link to={'/dashboard'}><img src={back} alt="Back button to dashboard."/></Link>
                    <p>{title}</p>
                </nav>
                {hasFinished !== true ? <Question title={questions[currentQuestionIndex].title} answers={questions[currentQuestionIndex].answers} handleAnswer={handleAnswer} correctAnswer={questions[currentQuestionIndex].correct_answer}/> : <RevisionResult score={score} />}
                <QuestionResult result={result} questionNum={perfectScore} />
            </div>
        );
    }
}
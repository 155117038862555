import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from './Loading';

export default function RevisionMenu({isLoggedIn, setIsLoggedIn}) {
    const [htmlDisplayRevisionSets, setHtmlDisplayRevisionSets] = useState([]);
    const [curriculum, setCurriculum] = useState('All curriculums')

    function changeCurriculum(e) {
        setCurriculum(e.target.value);
    }

    useEffect(() => {
        fetch('https://www.quaesium.serv00.net/api/revision-sets?data=title%2C%20id', {credentials: 'include'})
        .then((res) => {
            if (res.status === 401) {
                setIsLoggedIn(false);
                return 'no';
            } else {
                return res.text();
            }
        })
        .then((str) => {
            if (str !== 'no') {
                setHtmlDisplayRevisionSets(JSON.parse(str).map((e) => {return <Link to={`/revision/${e.id}`}><div key={e.id} className='set'><p className='title'>{e.title}</p><p>{e.id}</p></div></Link>}));
            }
        });
    }, [curriculum])

    return (
        <div className="revision-menu">
            <div className='sets'>
                <form action=''>
                    <select className='filter-select' value={curriculum} onChange={changeCurriculum}>
                        <option value={'All curriculums'}>All curriculums</option>
                        <option value={'IGSCE'}>IGSCE</option>
                    </select>
                </form>
                {htmlDisplayRevisionSets.length > 0 ? htmlDisplayRevisionSets : <Loading/>}
            </div>
        </div>
    )
}
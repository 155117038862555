import { Link } from "react-router-dom";

export default function Landing() {
  const handleLogin = () => {
    fetch('https://www.quaesium.serv00.net/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: 'testuser',
        password: 'password123'
      }),
      credentials: 'include'  // Ensures cookies are included in the request
    })
    .then(response => {
      return response.text();
    })
    .then(data => {
      console.log('Login successful:', data);
    })
    .catch(error => {
      console.error('Error during login:', error);
      alert('Login failed, please try again');
    });
  };

  const checkStatus = () => {
      fetch('https://www.quaesium.serv00.net/login-status', {
        method: 'GET',
        credentials: 'include',  // Ensures cookies are included in the request
      })
      .then(response => {
        return response.text();
      })
      .then(data => {
        console.log('Dashboard response:', data);
      })
      .catch(error => {
        console.error('Error accessing dashboard:', error);
        alert('Failed to access dashboard, please try again');
      });
  };

  const logOut = () => {
    fetch('https://www.quaesium.serv00.net/logout', {
      method: 'POST',
      credentials: 'include'  // Ensures cookies are included in the request
    })
    .then(response => {
      return response.text();
    })
    .then(data => {
      console.log(data);
    })
    .catch(error => {
      console.error(error);
    });
  }

  return (
    <div className="landing">
      <div className="hero">
        <h1>Empower Your Learning with Quaesium</h1>
        <p>Quaesium offers engaging courses tailored just for you. Learn faster - achieve more.</p>
        <button onClick={handleLogin}>Login</button>
        <button onClick={checkStatus}>DASHBOARD</button>
        <button onClick={logOut}>logout</button>
      </div>
      <Link to={'/dashboard'}>dashboard</Link>
      <Link to={'/login'}>login</Link>
      
    </div>
  );
}  